<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan Bahan PO</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/BahanPO">Data Bahan PO</a></li>
                                <li class="breadcrumb-item active">Tambah Data</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Bahan PO</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Program<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="program"
                                                    name="program" placeholder="Program" v-model="program">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kegiatan<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="kegiatan"
                                                    name="kegiatan" placeholder="Kegiatan" v-model="kegiatan">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Pekerjaan<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="pekerjaan"
                                                    name="pekerjaan" placeholder="Pekerjaan" v-model="pekerjaan">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>UPTD <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="uptd"
                                                    name="uptd" placeholder="UPTD" v-model="uptd">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>PPTK<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="pptk"
                                                    name="pptk" placeholder="PPTK" v-model="pptk">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>PPKOM<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="ppkom"
                                                    name="ppkom" placeholder="PPKOM" v-model="ppkom">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Sumber Dana<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="sumberdana"
                                                    name="sumberdana" placeholder="Sumber Dana" v-model="sumberdana">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tahun<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="tahun"
                                                    name="tahun" placeholder="Tahun" v-model="tahun">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Anggaran<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="anggaran"
                                                    name="anggaran" placeholder="Anggaran" v-model="anggaran">
                                            </div>
                                        </div>
                                        <div class="col-sm-12 previous" style="background-color: #fafafa;"
                                            v-for="(koodinatsetuju, counter) in koodinatsetujus" v-bind:key="counter">
                                            <div class="row">
                                                <div class="col-sm-12 text-left">
                                                    <label>Opsi Data {{ counter + 1 }}.&nbsp;</label>
                                                    <div class="col-sm-12 text-left">
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            @click="addKoordinat"><i
                                                                class="far fa-plus-square"></i></button>
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            @click="deleteKoordinat(counter)" v-if="(counter > 0)"><i
                                                                class="far fa-minus-square"></i></button>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Tanggal PO <small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="date" class="form-control form-control-sm"
                                                            :id="'tanggalpo' + (counter + 1)" name="tanggalpo"
                                                            placeholder="Tanggal PO" v-model="koodinatsetuju.tanggalpo">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Nomor PO <small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'nomorpo' + (counter + 1)" name="nomorpo"
                                                            placeholder="Nomor PO" v-model="koodinatsetuju.nomorpo">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Nilai Kontrak<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control form-control-sm"
                                                            :id="'nilaikontrak' + (counter + 1)" name="nilaikontrak"
                                                            placeholder="Nilai Kontrak"
                                                            v-model="koodinatsetuju.nilaikontrak">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Harga Satuan Kontrak<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control form-control-sm"
                                                            :id="'hargasatuankontrak' + (counter + 1)"
                                                            name="hargasatuankontrak" placeholder="Harga Satuan Kontrak"
                                                            v-model="koodinatsetuju.hargasatuankontrak">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Volume Kontrak<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control form-control-sm"
                                                            :id="'volumekontrak' + (counter + 1)" name="volumekontrak"
                                                            placeholder="Volume Kontrak"
                                                            v-model="koodinatsetuju.volumekontrak">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jenis Barang Kontrak<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'jenisbarangkontrak' + (counter + 1)"
                                                            name="jenisbarangkontrak" placeholder="Jenis Barang Kontrak"
                                                            v-model="koodinatsetuju.jenisbarangkontrak">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Satuan Barang Kontrak<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'satuankontrak' + (counter + 1)" name="satuankontrak"
                                                            placeholder="Satuan Barang Kontrak"
                                                            v-model="koodinatsetuju.satuankontrak">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Penyedia Barang<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'penyedia' + (counter + 1)" name="penyedia"
                                                            placeholder="Penyedia Barang"
                                                            v-model="koodinatsetuju.penyedia">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Pelaksana<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'pelaksana' + (counter + 1)" name="pelaksana"
                                                            placeholder="Pelaksana"
                                                            v-model="koodinatsetuju.pelaksana">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Konsultan Monitoring<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'konsultanmonitoring' + (counter + 1)" name="konsultanmonitoring"
                                                            placeholder="Konsultan Monitoring"
                                                            v-model="koodinatsetuju.konsultanmonitoring">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jenis Barang<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'jenisbarang' + (counter + 1)" name="jenisbarang"
                                                            placeholder="Jenis Barang"
                                                            v-model="koodinatsetuju.jenisbarang">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Volume<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control form-control-sm"
                                                            :id="'volume' + (counter + 1)" name="volume"
                                                            placeholder="Volume" v-model="koodinatsetuju.volume">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Satuan<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'satuan' + (counter + 1)" name="satuan"
                                                            placeholder="Satuan" v-model="koodinatsetuju.satuan">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Harga Satuan<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control form-control-sm"
                                                            :id="'hargasatuan' + (counter + 1)" name="hargasatuan"
                                                            placeholder="Harga Satuan"
                                                            v-model="koodinatsetuju.hargasatuan">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Tanggal Kirim <small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="date" class="form-control form-control-sm"
                                                            :id="'tanggalkirim' + (counter + 1)" name="tanggalkirim"
                                                            placeholder="Tanggal Kirim" v-model="koodinatsetuju.tanggalkirim">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Lokasi Kirim<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'lokasikirim' + (counter + 1)" name="lokasikirim"
                                                            placeholder="Lokasi Kirim" v-model="koodinatsetuju.lokasikirim">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>No Kendaraan Kirim<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'nokendaraan' + (counter + 1)" name="nokendaraan"
                                                            placeholder="No Kendaraan Kirim" v-model="koodinatsetuju.nokendaraan">
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Penanggungjawab<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'mandor' + (counter + 1)" name="mandor"
                                                            placeholder="Penanggungjawab" v-model="koodinatsetuju.mandor">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            program: '',
            kegiatan: '',
            pekerjaan: '',
            pptk: '',
            ppkom: '',
            nilaikontrak: '',
            hargasatuankontrak: '',
            volumekontrak: '',
            jenisbarangkontrak: '',
            satuankontrak: '',
            sumberdana: '',
            tahun: '',
            anggaran: '',
            penyedia: '',
            konsultanmonitoring: '',
            pelaksana: '',
            tanggalkirim: '',
            nokendaraan: '',
            lokasikirim: '',
            latitude: '',
            longitude: '',
            panjang: '',
            lebar: '',

            tanggalpo: '',
            nomorpo: '',
            uptd: '',
            counter: 0,
            koodinatsetujus: [
                {
                    previous: '',
                    expiration: '',
                }
            ],
            validasi: false,
        }
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        addKoordinat() {
            this.koodinatsetujus.push({
                previous: '',
                expiration: ''
            })
        },
        deleteKoordinat(counter) {
            this.koodinatsetujus.splice(counter, 1);
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/BahanPO");
        },
        validasidata() {
            if (document.getElementById('program').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('program').focus();
                return false;
            }
            if (document.getElementById('kegiatan').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('kegiatan').focus();
                return false;
            }
            if (document.getElementById('pekerjaan').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('pekerjaan').focus();
                return false;
            }
            if (document.getElementById('uptd').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('uptd').focus();
                return false;
            }
            if (document.getElementById('pptk').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('pptk').focus();
                return false;
            }
            if (document.getElementById('ppkom').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('ppkom').focus();
                return false;
            }
            if (document.getElementById('sumberdana').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('sumberdana').focus();
                return false;
            }
            if (document.getElementById('tahun').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tahun').focus();
                return false;
            }
            if (document.getElementById('anggaran').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('anggaran').focus();
                return false;
            }
            
            for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                if (document.getElementById('tanggalpo' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('tanggalpo' + i).focus();
                    return false;
                }
                if (document.getElementById('nomorpo' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('nomorpo' + i).focus();
                    return false;
                }
                if (document.getElementById('nilaikontrak' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('nilaikontrak' + i).focus();
                    return false;
                }
                if (document.getElementById('hargasatuankontrak' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('hargasatuankontrak' + i).focus();
                    return false;
                }
                if (document.getElementById('volumekontrak' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('volumekontrak' + i).focus();
                    return false;
                }
                if (document.getElementById('jenisbarangkontrak' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('jenisbarangkontrak' + i).focus();
                    return false;
                }
                if (document.getElementById('satuankontrak' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('satuankontrak' + i).focus();
                    return false;
                }
                if (document.getElementById('penyedia' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('penyedia' + i).focus();
                    return false;
                }
                if (document.getElementById('pelaksana' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('pelaksana' + i).focus();
                    return false;
                }
                if (document.getElementById('konsultanmonitoring' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('konsultanmonitoring' + i).focus();
                    return false;
                }
                if (document.getElementById('jenisbarang' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('jenisbarang' + i).focus();
                    return false;
                }
                if (document.getElementById('volume' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('volume' + i).focus();
                    return false;
                }
                if (document.getElementById('satuan' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('satuan' + i).focus();
                    return false;
                }
                if (document.getElementById('hargasatuan' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('hargasatuan' + i).focus();
                    return false;
                }
                if (document.getElementById('tanggalkirim' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('tanggalkirim' + i).focus();
                    return false;
                }
                if (document.getElementById('lokasikirim' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('lokasikirim' + i).focus();
                    return false;
                }
                if (document.getElementById('nokendaraan' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('nokendaraan' + i).focus();
                    return false;
                }
                if (document.getElementById('mandor' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('mandor' + i).focus();
                    return false;
                }

            }
            this.validasi = true;
            return;
        },
        simpan() {
            //alert(this.koodinatsetujus.length);
            //return false;
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Data Sudah Benar Dan Hendak Menyimpan Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#DC143C",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Simpan"
            }).then((result) => {
                if (result.isConfirmed) {

                    var fd = new FormData();
                    var tanggalpo = "";
                    var nomorpo = "";
                    var nilaikontrak = "";
                    var hargasatuankontrak = "";
                    var volumekontrak = "";
                    var jenisbarangkontrak = "";
                    var satuankontrak = "";
                    var penyedia = "";
                    var pelaksana = "";
                    var konsultanmonitoring = "";
                    var tanggalkirim = "";
                    var lokasikirim = "";
                    var nokendaraan = "";
                    var mandor = "";

                    var jenisbarang = "";
                    var volume = "";
                    var satuan = "";
                    var hargasatuan = "";
                    var jumlahharga = "";
                    for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                        if (tanggalpo == "") {
                            tanggalpo = tanggalpo + document.getElementById("tanggalpo" + i).value;
                        }
                        else {
                            tanggalpo = tanggalpo + "|" + document.getElementById("tanggalpo" + i).value;
                        }
                        if (nomorpo == "") {
                            nomorpo = nomorpo + document.getElementById("nomorpo" + i).value;
                        }
                        else {
                            nomorpo = nomorpo + "|" + document.getElementById("nomorpo" + i).value;
                        }
                        if (nilaikontrak == "") {
                            nilaikontrak = nilaikontrak + document.getElementById("nilaikontrak" + i).value;
                        }
                        else {
                            nilaikontrak = nilaikontrak + "|" + document.getElementById("nilaikontrak" + i).value;
                        }
                        if (hargasatuankontrak == "") {
                            hargasatuankontrak = hargasatuankontrak + document.getElementById("hargasatuankontrak" + i).value;
                        }
                        else {
                            hargasatuankontrak = hargasatuankontrak + "|" + document.getElementById("hargasatuankontrak" + i).value;
                        }
                        if (volumekontrak == "") {
                            volumekontrak = volumekontrak + document.getElementById("volumekontrak" + i).value;
                        }
                        else {
                            volumekontrak = volumekontrak + "|" + document.getElementById("volumekontrak" + i).value;
                        }
                        if (jenisbarangkontrak == "") {
                            jenisbarangkontrak = jenisbarangkontrak + document.getElementById("jenisbarangkontrak" + i).value;
                        }
                        else {
                            jenisbarangkontrak = jenisbarangkontrak + "|" + document.getElementById("jenisbarangkontrak" + i).value;
                        }
                        if (satuankontrak == "") {
                            satuankontrak = satuankontrak + document.getElementById("satuankontrak" + i).value;
                        }
                        else {
                            satuankontrak = satuankontrak + "|" + document.getElementById("satuankontrak" + i).value;
                        }
                        if (penyedia == "") {
                            penyedia = penyedia + document.getElementById("penyedia" + i).value;
                        }
                        else {
                            penyedia = penyedia + "|" + document.getElementById("penyedia" + i).value;
                        }
                        if (pelaksana == "") {
                            pelaksana = pelaksana + document.getElementById("pelaksana" + i).value;
                        }
                        else {
                            pelaksana = pelaksana + "|" + document.getElementById("pelaksana" + i).value;
                        }
                        if (konsultanmonitoring == "") {
                            konsultanmonitoring = konsultanmonitoring + document.getElementById("konsultanmonitoring" + i).value;
                        }
                        else {
                            konsultanmonitoring = konsultanmonitoring + "|" + document.getElementById("konsultanmonitoring" + i).value;
                        }
                        if (tanggalkirim == "") {
                            tanggalkirim = tanggalkirim + document.getElementById("tanggalkirim" + i).value;
                        }
                        else {
                            tanggalkirim = tanggalkirim + "|" + document.getElementById("tanggalkirim" + i).value;
                        }
                        if (lokasikirim == "") {
                            lokasikirim = lokasikirim + document.getElementById("lokasikirim" + i).value;
                        }
                        else {
                            lokasikirim = lokasikirim + "|" + document.getElementById("lokasikirim" + i).value;
                        }
                        if (nokendaraan == "") {
                            nokendaraan = nokendaraan + document.getElementById("nokendaraan" + i).value;
                        }
                        else {
                            nokendaraan = nokendaraan + "|" + document.getElementById("nokendaraan" + i).value;
                        }
                        if (mandor == "") {
                            mandor = mandor + document.getElementById("mandor" + i).value;
                        }
                        else {
                            mandor = mandor + "|" + document.getElementById("mandor" + i).value;
                        }


                        if (jenisbarang == "") {
                            jenisbarang = jenisbarang + document.getElementById("jenisbarang" + i).value;
                        }
                        else {
                            jenisbarang = jenisbarang + "|" + document.getElementById("jenisbarang" + i).value;
                        }
                        if (volume == "") {
                            volume = volume + document.getElementById("volume" + i).value;
                        }
                        else {
                            volume = volume + "|" + document.getElementById("volume" + i).value;
                        }

                        if (satuan == "") {
                            satuan = satuan + document.getElementById("satuan" + i).value;
                        }
                        else {
                            satuan = satuan + "|" + document.getElementById("satuan" + i).value;
                        }
                        if (hargasatuan == "") {
                            hargasatuan = hargasatuan + document.getElementById("hargasatuan" + i).value;
                        }
                        else {
                            hargasatuan = hargasatuan + "|" + document.getElementById("hargasatuan" + i).value;
                        }
                        if (jumlahharga == "") {
                            jumlahharga = jumlahharga + (document.getElementById("hargasatuan" + i).value * document.getElementById("volume" + i).value);
                        }
                        else {
                            jumlahharga = jumlahharga + "|" + (document.getElementById("hargasatuan" + i).value * document.getElementById("volume" + i).value);
                        }

                    }
                    fd.append("program", this.program);
                    fd.append("kegiatan", this.kegiatan);
                    fd.append("pekerjaan", this.pekerjaan);
                    fd.append("pptk", this.pptk);
                    fd.append("ppkom", this.ppkom);
                    fd.append("sumberdana", this.sumberdana);
                    fd.append("tahun", this.tahun);
                    fd.append("anggaran", this.anggaran);
                    fd.append("uptd", this.uptd);

                    fd.append("tanggalpo", tanggalpo);
                    fd.append("nomorpo", nomorpo);
                    fd.append("jenisbarang", jenisbarang);
                    fd.append("volume", volume);
                    fd.append("satuan", satuan);
                    fd.append("hargasatuan", hargasatuan);
                    fd.append("jumlahharga", jumlahharga);

                    fd.append("nilaikontrak", nilaikontrak);
                    fd.append("hargasatuankontrak", hargasatuankontrak);
                    fd.append("volumekontrak", volumekontrak);
                    fd.append("jenisbarangkontrak", jenisbarangkontrak);
                    fd.append("satuankontrak", satuankontrak);
                    fd.append("penyedia", penyedia);
                    fd.append("pelaksana", pelaksana);
                    fd.append("konsultanmonitoring", konsultanmonitoring);
                    fd.append("tanggalkirim", tanggalkirim);
                    fd.append("lokasikirim", lokasikirim);
                    fd.append("nokendaraan", nokendaraan);
                    fd.append("mandor", mandor);
                    fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            "Content-Type": "form-data",
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    mainAPI.post("parastapainnovation_po-AddBarangPO", fd).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                    window.location.replace("/BahanPO");
                                });
                            }
                        }
                    ).catch(function (error) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                    }
                    )
                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>