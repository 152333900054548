<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Investasi'">
    <Investasi/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiData'">
    <InvestasiData/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataKBLI'">
    <InvestasiDataKBLI/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataResiko'">
    <InvestasiDataResiko/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataStatus'">
    <InvestasiDataStatus/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataSektor'">
    <InvestasiDataSektor/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataPerusahaan'">
    <InvestasiDataPerusahaan/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetail'">
    <InvestasiDetail/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailKBLI'">
    <InvestasiDetailKBLI/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailResiko'">
    <InvestasiDetailResiko/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailStatus'">
    <InvestasiDetailStatus/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailSektor'">
    <InvestasiDetailSektor/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailPerusahaan'">
    <InvestasiDetailPerusahaan/>
  </div>
  <div class="content" v-if="route.name == 'Perizinan'">
    <Perizinan/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'DaftarRegulasi'">
    <DaftarRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'DaftarPasar'">
    <DaftarPasar/>
  </div>
  <div class="content" v-if="route.name == 'PantauanPasar'">
    <PantauanPasar/>
  </div>
  <div class="content" v-if="route.name == 'PotensiPasar'">
    <PotensiPasar/>
  </div>
  <div class="content" v-if="route.name == 'DaftarLapak'">
    <DaftarLapak/>
  </div>
  <div class="content" v-if="route.name == 'NSWI'">
    <NSWI/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'BahanMasuk'">
    <BahanMasuk/>
  </div>
  <div class="content" v-if="route.name == 'ProsesBahanMasuk'">
    <ProsesBahanMasuk/>
  </div>
  <div class="content" v-if="route.name == 'UpdateBahanMasuk'">
    <UpdateBahanMasuk/>
  </div>
  <div class="content" v-if="route.name == 'BahanKeluar'">
    <BahanKeluar/>
  </div>
  <div class="content" v-if="route.name == 'BahanPO'">
    <BahanPO/>
  </div>
  <div class="content" v-if="route.name == 'ProsesBahanPO'">
    <ProsesBahanPO/>
  </div>
  <div class="content" v-if="route.name == 'Regulasi'">
    <Regulasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRegulasi'">
    <ProsesRegulasi/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import DaftarRegulasi from './components/views/Publik/DaftarRegulasi.vue';
import DaftarPasar from './components/views/Publik/DaftarPasar.vue';
import PantauanPasar from './components/views/Publik/PantauanPasar.vue';
import PotensiPasar from './components/views/Publik/PotensiPasar.vue';
import DaftarLapak from './components/views/Publik/DaftarLapak.vue';
import GIS from './components/views/Publik/GIS.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import BahanMasuk from './components/views/Backoffice/BahanMasuk/BahanMasuk.vue';
import ProsesBahanMasuk from './components/views/Backoffice/BahanMasuk/ProsesBahanMasuk.vue';
import UpdateBahanMasuk from './components/views/Backoffice/BahanMasuk/UpdateBahanMasuk.vue';
import BahanKeluar from './components/views/Backoffice/BahanKeluar/BahanKeluar.vue';
import BahanPO from './components/views/Backoffice/BahanPO/BahanPO.vue';
import ProsesBahanPO from './components/views/Backoffice/BahanPO/ProsesBahanPO.vue';
import Regulasi from './components/views/Backoffice/Regulasi/Regulasi.vue';
import ProsesRegulasi from './components/views/Backoffice/Regulasi/ProsesRegulasi.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,KontakKami,DaftarRegulasi,DaftarPasar,PantauanPasar,PotensiPasar,DaftarLapak,GIS,Dashboard
    ,Slider,ProsesSlider,BahanMasuk,ProsesBahanMasuk
    ,UpdateBahanMasuk,BahanKeluar,Regulasi,ProsesRegulasi,InfoBeranda,ProsesInfoBeranda
    ,KategoriData,ProsesKategoriData,LayerPeta,ProsesLayerPeta,BahanPO,ProsesBahanPO
  },
  mounted() {
    document.title = "SIBALOK";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
